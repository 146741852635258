// React Required
import React from "react";

// Create Import File
import Home from "./features/Home";
import About from "./features/About";
import Contact from "./features/Contact";
import Values from "./features/Values";
import Contribution from "./features/Contribution";
import Management from "./features/Management";
import Branches from "./features/Branches";
import Branch from "./features/Branch";

// import Values from "./elements/Values";
// import Contribution from "./elements/Contribution";
// import Management from "./elements/Management";
// import Branches from "./elements/Branches";
// import Branch from "./elements/Branch";
// import Contact from "./elements/Contact";
// import Error404 from "./elements/error404";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// import { Switch, Route } from "react-router-dom";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/contact`}
          element={<Contact />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/about`}
          element={<About />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/values`}
          element={<Values />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/contribution`}
          element={<Contribution />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/management`}
          element={<Management />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/branches`}
          element={<Branches />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/branches/:branchName`}
          element={<Branch />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

//   <Switch>
//     <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />

// {
//    <Route
//         exact
//         path={`${process.env.PUBLIC_URL}/contact`}
//         component={Contact}
//       />
//       <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />

//       <Route
//         exact
//         path={`${process.env.PUBLIC_URL}/values`}
//         component={Values}
//       />

//       <Route
//         exact
//         path={`${process.env.PUBLIC_URL}/contribution`}
//         component={Contribution}
//       />

//       <Route
//         exact
//         path={`${process.env.PUBLIC_URL}/management`}
//         component={Management}
//       />
//       <Route
//         exact
//         path={`${process.env.PUBLIC_URL}/branches`}
//         component={Branches}
//       />
//       <Route
//         exact
//         path={`${process.env.PUBLIC_URL}/branches/:branchName`}
//         component={Branch}
//       />
//       <Route path={`${process.env.PUBLIC_URL}/404`} component={Error404} />
//       <Route component={Error404} /> */
//  }
//  </Switch>
