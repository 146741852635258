import React from "react";
import Helmet from "../components/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../components/Header";
import FooterTwo from "../components/FooterTwo";

const About = () => {
  return (
    <React.Fragment>
      <Helmet pageTitle="צור קשר" />
      <Header logo="light" color="color-white" />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image contact-top-section"
        data-black-overlay="1"
        style={{ background: " #029776" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title light-theme-gradient">
                  ניסיון. מקצועיות. יחס אישי.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="inner">
                  <h2>קצת עלינו</h2>
                  <p className="subtitle">מעל 32 שנות ניסיון</p>
                  <p>
                    חברת "ארזים שירותי סיעוד ורווחה" הנה חברה פרטית למתן שירותי
                    סיעוד, אשר הוקמה בשנת 1988 ומתמחה בהשמה מקצועית ואישית של
                    מטפלים סיעודיים, עבור קשישים, נכים וחולים הזקוקים לטיפול 24
                    שעות ביממה. החברה בעלת אישור למתן שירותי חוק ביטוח סיעוד של
                    המוסד לביטוח לאומי ועובדת שנים רבות בשיתוף פעולה עם גופים
                    גדולים לרבות משרד הביטחון, קרן נפגעי שואה וקופות החולים
                    השונות.
                  </p>

                  <p className="subtitle">רשת סניפים בפרישה ארצית</p>
                  <p>
                    ארזים נותנת שירות לקשישים ברשויות מקומיות רבות ברחבי הארץ
                    ולרשותה מערך סניפים בפריסה ארצית. ארזים מספקת מענה כולל
                    ללקוחותיה החל מהיום הראשון בו מתעורר הצורך בשירותיה, ובתוך
                    כך מעניקה סיוע וייעוץ, ללא כל תמורה, תוך ליווי לקוחותיה
                    באופן שוטף מול רשויות ומוסדות המדינה, עד למיצוי זכויותיהם
                    הסוציאליות והשגת ההטבות המגיעות להם על פי דין.
                  </p>
                  <p className="subtitle">מתן מענה למקרים סיעודיים ומורכבים</p>
                  <p>
                    עובדי ארזים הוכשרו להעניק טיפול למקרים סיעודיים מורכבים
                    וקשים ובכלל זה לפגועי נפש וחולי אלצהיימר; סרטן; פרקינסון;
                    נכויות שונות; אוסטאופורוזיס; מחלות עיניים; חולים סוכרתיים;
                    וכן אנשים הנמצאים במצב סיעודי מורכב ומרותקים למיטה.
                  </p>
                  <p className="subtitle">צוות מקצועי איכותי ונרחב</p>
                  <p>
                    לשם הבטחת טיב השירות ואיכות כוח האדם שהחברה מספקת, מעמידה
                    ארזים צוות מקצועי של אחיות, עובדות סוציאליות וגרנטולוגיות,
                    וכן נערך סינון קפדני של כוח האדם המגויס, תוך עמידה על
                    סטנדרטים גבוהים וקפדניים הן מבחינת היכולות המקצועיות
                    והבינאישיות והן בהיבט הרמה האינטלקטואלית הנדרשת.
                  </p>
                  <p className="subtitle">
                    התאמה מיטבית בין המטפל ובין המטופל ומשפחתו
                  </p>
                  <p>
                    בתוך כך, ארזים דואגת לבצע התאמה פרטנית ואישית בין המטפל ובין
                    המטופל ומשפחתו, תוך שנלקחים בחשבון שפתו, מנהגיו, וצרכיו של
                    המטופל, לצד אישיותו ודרישותיו הסובייקטיביות.
                  </p>
                  <p className="subtitle">עמידה בתקני איכות מחמירים</p>
                  <p>
                    ארזים מקפידה שנים רבות על עמידה בתקני איכות מחמירים. לחברה
                    תעודה מאת היחידה לאיכות והסמכה, מוסד הטכניון למחקר ופיתוח
                    בע"מ, וחברת DNV העולמית, המאשרת כי החברה עומדת בתקנים
                    המחמירים לאיכות ע"פ "תקן מערכת ניהול האיכות" ISO 9001:2008
                    עבור מתן שירותי סיעוד וכוח אדם סיעודי.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <FooterTwo />
    </React.Fragment>
  );
};
export default About;
