import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img
              src={require("../assets/images/brand/btl-logo.png")}
              alt="המוסד לביטוח לאומי"
            />
          </li>
          <li>
            <img
              src={require("../assets/images/brand/keren-logo.png")}
              alt="הקרן לרווחת נפגעי השואה"
            />
          </li>
          <li>
            <img
              src={require("../assets/images/brand/social-security-logo.png")}
              alt="משרד העבודה והרווחה"
            />
          </li>
          <li>
            <img
              src={require("../assets/images/brand/misrad-habitahon-logo.png")}
              alt="משרד הביטחון"
            />
          </li>
          <li>
            <img
              src={require("../assets/images/brand/macabi-logo.png")}
              alt="קופת חולים מכבי"
            />
          </li>
          <li>
            <img
              src={require("../assets/images/brand/leumit-logo.png")}
              alt="קופת חולים לאומית"
            />
          </li>
          <li>
            <img
              src={require("../assets/images/brand/klalit-logo.png")}
              alt="קופת חולים כללית"
            />
          </li>
          <li>
            <img
              src={require("../assets/images/brand/meuhedet-logo.png")}
              alt="קופת חולים מאוחדת"
            />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
