import React, { Component, useEffect } from "react";
import Helmet from "../components/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../components/Header";
import FooterTwo from "../components/FooterTwo";
import GoogleMapReact from "google-map-react";
import { Navigate } from "react-router-dom";
import ContactTwo from "../components/ContactTwo";
import data from "../components/data.json";
import { useParams } from "react-router-dom";
import Marker from "../components/Marker";

const Branch = () => {
  let { branchName } = useParams();
  const selected = data.filter((i) => i.branchName === branchName)[0];
  if (!selected) {
    return <Navigate to="/404" />;
  }
  return (
    <React.Fragment>
      <Helmet pageTitle="צור קשר" />
      <Header logo="light" color="color-white" />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image contact-top-section"
        data-black-overlay="1"
        style={{ background: " #029776" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title light-theme-gradient">{selected.name}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="inner" style={{ padding: 0 }}>
                  <h2 style={{ marginBottom: "20px" }}>פרטי הסניף</h2>

                  <div className="portfolio-view-list d-flex flex-wrap">
                    <div className="port-view">
                      <span>כתובת הסניף</span>
                      <h4>{selected.address}</h4>
                    </div>
                    <div className="port-view">
                      <span>טלפון</span>
                      <h4>{selected.phone}</h4>
                    </div>

                    <div className="port-view">
                      <span>פקס</span>
                      <h4>{selected.fax}</h4>
                    </div>

                    <div className="port-view">
                      <span>דואר אלקטרוני</span>
                      <h4>{selected.email}</h4>
                    </div>
                  </div>

                  <p className="subtitle">שעות פתיחה</p>
                  <p style={{ margin: 0 }}>א'-ה': 8:00-16:00</p>
                  <p>ו' וערבי חג: 8:00-12:00</p>
                  {selected &&
                    selected.employees &&
                    selected.employees.length > 0 &&
                    selected.employees.map((value, i) => (
                      <div>
                        {value.name && <p className="subtitle">{value.name}</p>}
                        {value.resume && <p>{value.resume}</p>}
                        {value.values && (
                          <>
                            <p className="subtitle">אני מאמין</p>
                            <p>{value.values}</p>
                          </>
                        )}
                        {value.mail && (
                          <div className="portfolio-view-list d-flex flex-wrap">
                            <div className="port-view">
                              <span>דואר אלקטרוני</span>
                              <h4>{value.mail}</h4>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}
      <div
        style={{
          background: "#F7F8FD",
          padding: "50px",
        }}
      >
        <div className="rn-contact-map-area position-relative">
          <div style={{ height: "650px", width: "100%" }}>
            <GoogleMapReact
              defaultCenter={{
                lat: selected.latitude,
                lng: selected.longitude,
              }}
              defaultZoom={18}
            >
              <Marker lat={selected.latitude} lng={selected.longitude} />
            </GoogleMapReact>
          </div>
        </div>
      </div>
      <ContactTwo branch={selected} />
      <div style={{ height: "20px" }} />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <FooterTwo />
    </React.Fragment>
  );
};

export default Branch;
