import React from "react";
import ContactForm from "./ContactForm";

const ContactTwo = (props) => {
  const { branch } = props;
  return (
    <div
      className="contact-form--1"
      style={{
        // background: branch ? "#F7F8FD" : "none",
        paddingTop: branch ? "50px" : 0,
      }}
    >
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">
                {branch ? `צרו קשר עם ${branch.name}` : `צרו קשר`}
              </h2>
              <p className="description">
                השאירו פרטים וניצור עמכם קשר בההקדם האפשרי
              </p>
            </div>
            <div className="form-wrapper">
              <ContactForm branch={branch} />
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="thumbnail mb_md--30 mb_sm--30">
              <img
                style={{ boxShadow: "none" }}
                src={require("../assets/images/contact/phone.png")}
                alt="ארזים שירותי סיעוד ורווחה"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactTwo;
