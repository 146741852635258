import React from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

const Header = (props) => {
  const { logo, color = "default-color" } = props;
  let logoUrl;
  if (logo === "light") {
    logoUrl = (
      <img
        src={require("../assets/images/logo/arazim-logo.png")}
        alt="ארזים שירותי סיעוד ורווחה"
      />
    );
  } else if (logo === "dark") {
    logoUrl = (
      <img
        src={require("../assets/images/logo/logo-dark.png")}
        alt="ארזים שירותי סיעוד ורווחה"
      />
    );
  } else if (logo === "symbol-dark") {
    logoUrl = (
      <img
        src={require("../assets/images/logo/logo-symbol-dark.png")}
        alt="ארזים שירותי סיעוד ורווחה"
      />
    );
  } else if (logo === "symbol-light") {
    logoUrl = (
      <img
        src={require("../assets/images/logo/logo-symbol-light.png")}
        alt="ארזים שירותי סיעוד ורווחה"
      />
    );
  } else {
    logoUrl = (
      <img src="/assets/images/logo/logo.png" alt="ארזים שירותי סיעוד ורווחה" />
    );
  }

  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  const cLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };

  return (
    <header
      className={`header-area formobile-menu header--transparent ${color}`}
    >
      <div className="header-wrapper" id="header-wrapper">
        <div className="header-left">
          <div className="logo">
            <a href="/">{logoUrl}</a>
          </div>
        </div>
        <div className="header-right">
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              <li>
                <Link to="/management">הנהלת החברה</Link>
              </li>
              <li>
                <Link to="/contribution">תרומה לקהילה</Link>
              </li>

              <li>
                <Link to="/values">ערכים</Link>
              </li>
              <li>
                <Link to="/about">אודותינו</Link>
              </li>
              <li>
                <Link to="/branches">סניפים</Link>
              </li>
            </ul>
          </nav>
          <div className="header-btn">
            <a className="rn-btn">
              <span>
                <Link to="/contact">יצירת קשר</Link>
              </span>
            </a>
          </div>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span onClick={menuTrigger} className="menutrigger text-white">
              <FiMenu />
            </span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span onClick={cLoseMenuTrigger} className="closeTrigger">
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
