import React, { Component } from "react";
import { Link } from "react-router-dom";
import data from "./data.json";

class BranchesList extends Component {
  render() {
    const { column, styevariation } = this.props;
    const list = data.slice(0, this.props.item);
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`${column}`} key={index}>
            <div className={`portfolio ${styevariation}`}>
              <div className="thumbnail-inner">
                <div className={`thumbnail`}></div>
                <div className={`bg-blr-image `}></div>
              </div>
              <div className="content">
                <div className="inner">
                  <h4>
                    <a href="/portfolio-details">{value.name}</a>
                  </h4>
                  <p style={{ margin: 0, lineHeight: "20px" }}>
                    {value.address}
                  </p>
                  <p style={{ margin: 0, lineHeight: "20px" }}>{value.phone}</p>
                  <p style={{ margin: 0, lineHeight: "20px" }}>{value.email}</p>

                  <div className="portfolio-button">
                    <a className="rn-btn" href={`branches/${value.branchName}`}>
                      צפיה בפרטי הסניף
                    </a>
                  </div>
                </div>
              </div>
              <Link
                className="link-overlay"
                to={`branches/${value.branchName}`}
              ></Link>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default BranchesList;
