import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyA3WHr6R6aU50BR9rJtO3UYR50dwAx0lpc",
  authDomain: "arazim-627c6.firebaseapp.com",
  projectId: "arazim-627c6",
  storageBucket: "arazim-627c6.appspot.com",
  messagingSenderId: "51925021646",
  appId: "1:51925021646:web:16d1a96fbd61d76978719f",
  measurementId: "G-S8157NVK91",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

class Auth {
  async signInWithEmailAndPassword(email, password) {
    const userInfo = await app
      .auth()
      .signInWithEmailAndPassword(email, password);
  }

  async addAContact(values) {
    const { name, mail, phone, message } = values;
    const newContact = await addDoc(collection(db, "contact"), {
      name,
      mail,
      phone,
      content: message,
      to: `yogev@arazim.co, ${mail}`,
      message: {
        subject: "פניה חדשה מאתר ארזים",
        text: `${name} ${phone} ${message}`,
      },
    });
    return newContact;
  }
}

export default new Auth();
