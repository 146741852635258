import React from "react";
import { FiMapPin } from "react-icons/fi";

const Marker = () => {
  return (
    <div className="icon" style={{}}>
      <FiMapPin style={{ width: "30px", height: "30px", color: "#f9004d" }} />
    </div>
  );
};

export default Marker;
