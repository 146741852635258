import React, { Fragment } from "react";
import Slider from "react-slick";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import { slideSlick } from "../components/slick";
import ServiceList from "../components/ServiceList";
import Header from "../components/Header";
import FooterTwo from "../components/FooterTwo";
import BranchesList from "../components/BranchesList";
import Brand from "../components/BrandTwo";
import CallAction from "../components/CallAction";
import TabOne from "../components/TabOne";
import Helmet from "../components/Helmet";

const SlideList = [
  {
    textPosition: "text-center",
    // bgImage: "bg_image--21",
    category: "",
    title: "כל פתרונות הסיעוד במקום אחד",
    description:
      "עם למעלה מ- 27 שנות ניסיון ואלפי לקוחות מרוצים, חברת ארזים תאפשר לכם לקבל את כל הדרוש לכם בתחום הסיעוד, החל מליווי בשלב מיצוי הזכויות וההיתרים ועד השמה מוצלחת של מטפלת סיעודית בביתכם.",
    buttonText: "צור קשר",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-center",
    // bgImage: "bg_image--23",
    category: "",
    title: "מטפלים באהבה",
    description:
      "אנחנו מטפלים בלקוחותינו כמו שהיינו רוצים שיטפלו בבני משפחותינו. באופן אנושי ועם הרבה מסירות ואהבה.",
    buttonText: "צור קשר",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-center",
    // bgImage: "bg_image--22",
    category: "",
    title: "מלווים אתכם יד ביד בכל התהליך",
    description:
      "העבירו אלינו את המושכות ואפשרו לנו להעניק ליקיריכם מענה מקיף, מקצועי ואישי עד אשר יימצא הפיתרון המיטבי, לשביעות רצונכם.",
    buttonText: "צור קשר",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-center",
    // bgImage: "bg_image--20",
    category: "",
    title: "שירותי מטפלים זרים",
    description:
      "לארזים ניסיון וידע רב בתחום ונסייע לכם משלב בקשת היתר להעסקת מטפל זר, מימון, בחירת המטפל, קבלת הרישיונות ועוד, נהיה לצידכם לכל אורך הדרך.",
    buttonText: "צור קשר",
    buttonLink: "/contact",
  },
];

const Home = (props) => {
  let title = "קצת עלינו",
    description =
      "חברת ארזים שירותי סיעוד ורווחה הנה חברה פרטית למתן שירותי סיעוד, אשר הוקמה בשנת 1988 ומתמחה בהשמה מקצועית ואישית של מטפלים סיעודיים, עבור קשישים, נכים וחולים הזקוקים לטיפול 24 שעות ביממה. החברה בעלת אישור למתן שירותי חוק ביטוח סיעוד של המוסד לביטוח לאומי ועובדת שנים רבות בשיתוף פעולה עם גופים גדולים לרבות משרד הביטחון, קרן נפגעי שואה וקופות החולים השונות.";
  return (
    <Fragment>
      <Helmet pageTitle="ארזים שירותי סיעוד ורווחה" />

      {/* Start Header Area  */}
      <Header logo="light" color="color-white" />
      {/* End Header Area  */}

      {/* Start Slider Area   */}
      <div className="slider-wrapper color-white">
        <div className="slider-activation slider-digital-agency">
          <Slider className="rn-slick-dot dot-light" {...slideSlick}>
            {SlideList.map((value, index) => (
              <div
                className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                key={index}
                data-black-overlay="2"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1 className="title">{value.title}</h1>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a
                              className="rn-button-style--2 btn-primary-color"
                              href={`${value.buttonLink}`}
                            >
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start Service Area  */}
      <div className="service-area pt--120 pb--50 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30">
                <h2 className="title">למה ארזים</h2>
                <p>
                  לארזים עשרות שנות ניסיון, סניפים בפרישה ארצית וצוות מקצועי
                  נרחב המאפשר לספק מענה למקרים המורכבים ביותר
                </p>
              </div>
            </div>
          </div>
          <ServiceList
            item="6"
            column="col-lg-4 col-md-6 col-sm-6 col-12 text-center"
          />
          <div className="row">
            <div className="col-lg-12">
              <div className="view-more-btn mt--60 text-center">
                <a className="rn-button-style--2 btn-solid" href="/about">
                  <span>קרא עוד</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}

      {/* Start About Area */}
      <div className="about-area ptb--120  bg_image--3">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={require("../assets/images/about/about-10.png")}
                    alt="על החברה"
                    style={{ boxShadow: "none" }}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30">
                    <TabOne tabStyle="tab-style--1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}

      {/* Start Portfolio Area */}
      <div className="portfolio-area ptb--120 bg_image bg_color--1">
        <div className="portfolio-sacousel-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--15">
                  <h2 className="title">סניפי החברה</h2>
                  <p>
                    לארזים סניפים רבים בפרישה ארצית. מצאו את הסניף הקרוב למקום
                    מגוריכם ופנו אליו ישירות למתן מענה מיידי.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <BranchesList
                styevariation="text-center mt--40"
                column="col-lg-4 col-md-6 col-sm-6 col-12"
                item="18"
              />
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--60 text-center">
                  <a className="rn-button-style--2 btn-solid" href="/portfolio">
                    <span>פרטי הסניפים</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start call To Action  */}
      <CallAction />
      {/* End call To Action  */}

      {/* Start Brand Area */}
      <div className="rn-brand-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30">
                <h2 className="title">בין לקוחותינו</h2>
                <p>
                  ארזים בעלת אישור למתן שירותי חוק ביטוח סיעוד של המוסד לביטוח
                  לאומי ועובדת שנים רבות בשיתוף פעולה עם גופים גדולים לרבות משרד
                  הביטחון, קרן נפגעי שואה וקופות החולים השונות.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt--40">
              <Brand branstyle="branstyle--2" />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area */}

      {/* Start Footer Style  */}
      <FooterTwo />
      {/* End Footer Style  */}
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </Fragment>
  );
};

export default Home;
