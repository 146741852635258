import React from "react";
import Helmet from "../components/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../components/Header";
import FooterTwo from "../components/FooterTwo";

const Contribution = () => {
  return (
    <React.Fragment>
      <Helmet pageTitle="צור קשר" />
      <Header logo="light" color="color-white" />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image contact-top-section"
        data-black-overlay="1"
        style={{ background: " #029776" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title light-theme-gradient">
                  נתינה ותרומה מכל הלב
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="inner">
                  <h2>תרומה לקהילה</h2>
                  <p className="subtitle">
                    ייעוץ וסיוע לקשישים ומשפחותיהם ללא כל עלות
                  </p>
                  <p>
                    ארזים מספקת מענה כולל ללקוחותיה החל מהיום הראשון בו מתעורר
                    הצורך בשירותיה, ובתוך כך מעניקה סיוע וייעוץ, ללא כל תמורה,
                    תוך ליווי לקוחותיה באופן שוטף מול רשויות ומוסדות המדינה, עד
                    למיצוי זכויותיהם הסוציאליות והשגת ההטבות המגיעות להם על פי
                    דין.
                  </p>

                  <p className="subtitle">הרצאות וסדנאות ללא עלות לקהילה</p>
                  <p>
                    מעבר לשירותים השוטפים, מעניקה ארזים לקהילות הקשישים השונות
                    באזורים אשר נמצאים בגדר תחומי פעילותה, הרצאות וסדנאות ללא כל
                    תמורה במגוון נושאים הקרובים לליבם, החל בהרצאות בענייני דיני
                    ירושה וצוואות המועברות על ידי משפטנים מוסמכים וכלה בהרצאות
                    מקצועיות לגיל השלישי הנוגעות להתמודדות עם משברי הזקנה, תזונה
                    נכונה, שיפור איכות החיים ועו
                  </p>
                  <p className="subtitle">
                    תוכנית "והדרת" - חונכות בנות כיתה ט' אצל קשישים
                  </p>
                  <p>
                    ארזים משתפת פעולה עם תוכנית "והדרת", במסגרתה בנות מכיתה ט'
                    מאולפנית "מבשרת ברוך" בחדרה, מתנדבות שעתיים בשבוע; ארזים
                    מאפשרת ומעודדת את אותן מתנדבות לבצע פעילויות התנדבותיות
                    בבתיהן של קשישות הגרות בסמיכות לבית הספר. לשמחתנו, המפגש בין
                    הקשישות ובין המתנדבות הצעירות, הוביל לדינמיקה מרתקת ומפרה
                    וסינרגיה הדדית, כאשר המתנדבות הצעירות הכניסו שמחה, רעננות
                    ותחושת חיות לבתי הקשישות, ואילו הקשישות בחרו לשתף את הבנות
                    לא אחת בתחושות או אירועים שעברו במהלך חייהן וכן להשיא להן
                    עצות מועילות מניסיון חייהן.
                  </p>
                  <p className="subtitle">
                    שיתוף פעולה עם המקלט לנשים מוכות בחדרה
                  </p>
                  <p>
                    ארזים משתפת פעולה עם מקלט לנשים מוכות, ומאפשרת לנשים השוהות
                    במקלט, ובשלות מבחינת התהליך הטיפולי שהן עוברות, לפנות אלינו
                    ולהתקבל לעבודה כמטפלות בחברה. שיתוף פעולה עם דירת המשך לבנות
                    שסיימו פנימיה ואין להן בית לחזור אליו - עמותת "תלפיות" ארזים
                    יצרה קשר עם גורמים האחראים על בנות שסיימו פנימיה ונמצאות
                    לפני או אחרי צבא, ללא דיור קבוע, ומציעה לאותן בנות תעסוקה
                    זמנית כעובדות בחברה.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <FooterTwo />
    </React.Fragment>
  );
};
export default Contribution;
