import React from "react";
import Helmet from "../components/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../components/Header";
import FooterTwo from "../components/FooterTwo";
import BranchesList from "../components/BranchesList";

const Branches = () => {
  return (
    <React.Fragment>
      <Helmet pageTitle="צור קשר" />
      <Header logo="light" color="color-white" />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image contact-top-section"
        data-black-overlay="1"
        style={{ background: " #029776" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title light-theme-gradient">
                  איתכם לאורך כל הדרך. ארזים.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="portfolio-area ptb--120 bg_image bg_color--1">
        <div className="portfolio-sacousel-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--15">
                  <h2 className="title">סניפי החברה</h2>
                  <p>
                    לארזים סניפים רבים בפרישה ארצית. מצאו את הסניף הקרוב למקום
                    מגוריכם ופנו אליו ישירות למתן מענה מיידי.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <BranchesList
                styevariation="text-center mt--40"
                column="col-lg-4 col-md-6 col-sm-6 col-12"
                item="18"
              />
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--60 text-center">
                  <a className="rn-button-style--2 btn-solid" href="/portfolio">
                    <span>פרטי הסניפים</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <FooterTwo />
    </React.Fragment>
  );
};
export default Branches;
