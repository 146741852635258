import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FiCheck } from "react-icons/fi";

class TabsOne extends Component {
  render() {
    let tab1 = "הערכים שלנו",
      tab2 = "תרומה לקהילה";
    // tab3 = "Friendly Support";
    const { tabStyle } = this.props;
    var namesItemOne = [
      "The Philosophy Of business analytics",
      "Fast-Track Your business",
      "Lies And Damn Lies About business",
      "The Ultimate Deal On business",
    ];

    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <h4>כנות ושותפות</h4>
                      <p>
                        אנו מקפידים החל מיצירת הקשר הראשוני, על שותפות הדדית
                        מלאה עם המטופל ומשפחתו, תוך עדכונם בכל תהליך אבחון מצב
                        המטופל וגיבוש הפתרונות המתאימים עבורו.
                      </p>

                      <h4>סובלנות</h4>
                      <p>
                        היכולת להכיל, להקשיב ולהיות סובלנים כלפי רגישויותיהם של
                        המטופל ומשפחתו, הינם חלק אינהרנטי, מרכזי ומשמעותי במתן
                        השירות ללקוחותינו.
                      </p>

                      <h4>מקצועיות</h4>
                      <p>
                        החברה גבשה במהלך השנים שדרה מקצועית איכותית ונרחבת
                        המגבשת פתרונות סיעודיים ומפקחת על איכות השירות הניתן
                        ללקוחות החברה. כל מקרה, פשוט ככל שיהיה, יזכה אצלנו למענה
                        הולם והתייחסות מעמיקה.
                      </p>

                      <h4>אנושיות ומסירות</h4>
                      <p>
                        אנחנו מטפלים בלקוחותינו כמו שהיינו רוצים שיטפלו בבני
                        משפחותינו. באופן אנושי ועם הרבה מסירות ואהבה.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <h4>ייעוץ וסיוע לקשישים ומשפחותיהם ללא כל עלות</h4>
                      <p>
                        ארזים מספקת מענה כולל ללקוחותיה החל מהיום הראשון בו
                        מתעורר הצורך בשירותיה, ובתוך כך מעניקה סיוע וייעוץ, ללא
                        כל תמורה, תוך ליווי לקוחותיה באופן שוטף מול רשויות
                        ומוסדות המדינה, עד למיצוי זכויותיהם הסוציאליות והשגת
                        ההטבות המגיעות להם על פי דין.
                      </p>

                      <h4>הרצאות וסדנאות ללא עלות לקהילה</h4>
                      <p>
                        מעבר לשירותים השוטפים, מעניקה ארזים לקהילות הקשישים
                        השונות באזורים אשר נמצאים בגדר תחומי פעילותה, הרצאות
                        וסדנאות ללא כל תמורה במגוון נושאים הקרובים לליבם, החל
                        בהרצאות בענייני דיני ירושה וצוואות המועברות על ידי
                        משפטנים מוסמכים וכלה בהרצאות מקצועיות לגיל השלישי
                        הנוגעות להתמודדות עם משברי הזקנה, תזונה נכונה, שיפור
                        איכות החיים ועוד.
                      </p>

                      <h4>תוכנית והדרת - חונכות בנות כיתה ט' אצל קשישים</h4>
                      <p>
                        ארזים משתפת פעולה עם תוכנית "והדרת", במסגרתה בנות מכיתה
                        ט' מאולפנית "מבשרת ברוך" בחדרה, מתנדבות שעתיים בשבוע;
                        ארזים מאפשרת ומעודדת את אותן מתנדבות לבצע פעילויות
                        התנדבותיות בבתיהן של קשישות הגרות בסמיכות לבית הספר.
                      </p>

                      <h4>שיתוף פעולה עם המקלט לנשים מוכות בחדרה</h4>
                      <p>
                        ארזים משתפת פעולה עם מקלט לנשים מוכות, ומאפשרת לנשים
                        השוהות במקלט, ובשלות מבחינת התהליך הטיפולי שהן עוברות,
                        לפנות אלינו ולהתקבל לעבודה כמטפלות בחברה. שיתוף פעולה עם
                        דירת המשך לבנות שסיימו פנימיה ואין להן בית לחזור אליו -
                        עמותת "תלפיות" ארזים יצרה קשר עם גורמים האחראים על בנות
                        שסיימו פנימיה ונמצאות לפני או אחרי צבא, ללא דיור קבוע,
                        ומציעה לאותן בנות תעסוקה זמנית כעובדות בחברה.
                      </p>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsOne;
