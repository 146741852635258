import React from "react";
import Helmet from "../components/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../components/Header";
import FooterTwo from "../components/FooterTwo";

const Values = () => {
  return (
    <React.Fragment>
      <Helmet pageTitle="צור קשר" />
      <Header logo="light" color="color-white" />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image contact-top-section"
        data-black-overlay="1"
        style={{ background: " #029776" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title light-theme-gradient">
                  כנות, סובלנות ואנושיות
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="inner">
                  <h2>ערכי החברה</h2>
                  <p className="subtitle">כנות ושותפות</p>
                  <p>
                    אנו מקפידים החל מיצירת הקשר הראשוני, על שותפות הדדית מלאה עם
                    המטופל ומשפחתו, תוך עדכונם בכל תהליך אבחון מצב המטופל וגיבוש
                    הפתרונות המתאימים עבורו.
                  </p>

                  <p className="subtitle">סובלנות</p>
                  <p>
                    היכולת להכיל, להקשיב ולהיות סובלנים כלפי רגישויותיהם של
                    המטופל ומשפחתו, הינם חלק אינהרנטי, מרכזי ומשמעותי במתן
                    השירות ללקוחותינו.
                  </p>
                  <p className="subtitle">מקצועיות</p>
                  <p>
                    החברה גבשה במהלך השנים שדרה מקצועית איכותית ונרחבת המגבשת
                    פתרונות סיעודיים ומפקחת על איכות השירות הניתן ללקוחות החברה.
                    כל מקרה, פשוט ככל שיהיה, יזכה אצלנו למענה הולם והתייחסות
                    מעמיקה.
                  </p>
                  <p className="subtitle">אנושיות ומסירות</p>
                  <p>
                    אנחנו מטפלים בלקוחותינו כמו שהיינו רוצים שיטפלו בבני
                    משפחותינו. באופן אנושי ועם הרבה מסירות ואהבה.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <FooterTwo />
    </React.Fragment>
  );
};
export default Values;
