import React, { useState } from "react";
import data from "./data.json";
import Auth from "../api/auth";

const Result = () => {
  return (
    <p className="success-message">הטופס נשלח בהצלחה. ייצרו עמך קשר בהקדם.</p>
  );
};

const ContactForm = (props) => {
  const { branch } = props;
  const [result, showresult] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    var elements = document.getElementById("contact-form").elements;
    var obj = {};
    for (var i = 0; i < elements.length; i++) {
      var item = elements.item(i);
      obj[item.name] = item.value;
    }
    e.target.reset();
    const { name, mail, phone, message } = obj;
    const values = { name, mail, phone, message };
    const doc = await Auth.addAContact(values);
    if (doc.id) {
      showresult(true);
    }
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return result ? (
    <Result />
  ) : (
    <form id="contact-form" action="" onSubmit={sendEmail}>
      <div className="rn-form-group">
        <input type="text" name="name" placeholder="השם שלך" required />
      </div>
      <div className="rn-form-group">
        <input type="text" name="phone" placeholder="מספר טלפון" required />
      </div>
      {!branch && (
        <div className="select_box">
          <select name="mail" id="branches" placeholder="סניף מבוקש">
            <option value={""}>בחר סניף</option>
            {data.map((value, index) => (
              <option value={value.email}>{value.name}</option>
            ))}
          </select>
        </div>
      )}

      <div className="rn-form-group">
        <textarea name="message" placeholder="תוכן הפנייה" required></textarea>
      </div>

      <div className="rn-form-group">
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="submit"
          id="mc-embedded-subscribe"
        >
          שלח
        </button>
      </div>
    </form>
  );
};
export default ContactForm;
