import React from "react";
import "./App.css";
import PageScrollTop from "./components/PageScrollTop";
import Router from "./router";

function App() {
  return (
    <PageScrollTop>
      <Router />
    </PageScrollTop>
  );
}

export default App;
