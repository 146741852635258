import React from "react";
import Helmet from "../components/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../components/Header";
import FooterTwo from "../components/FooterTwo";

const Management = () => {
  return (
    <React.Fragment>
      <Helmet pageTitle="צור קשר" />
      <Header logo="light" color="color-white" />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image contact-top-section"
        data-black-overlay="1"
        style={{ background: " #029776" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title light-theme-gradient">
                  איתכם לאורך כל הדרך. ארזים.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="inner">
                  <h2>הנהלת החברה</h2>
                  <p className="subtitle">עו"ד יוגב ארז | מנכ"ל</p>
                  <p>
                    {" "}
                    בעל תואר ראשון בוגר אוניברסיטה במשפטים (LL.B), אוניברסיטת תל
                    אביב; בעל תואר שני מוסמך במנהל עסקים (M.B.A), אוניברסיטת תל
                    אביב; חבר בלשכת עורכי הדין בישראל; רב סרן במיל' בצנחנים.
                    ייסד במהלך לימודי התואר הראשון תכנית חינוכית בעיר יהוד שפנתה
                    לילדים מצטיינים, מתוך רצון לפתוח בפניהם את שערי ההשכלה
                    וליצור מנהיגים חיוביים שיוכלו לחולל שינוי אמיתי; מנכ״ל של
                    חברת ארזים שירותי סיעוד ורווחה בע"מ משנת 2009.
                  </p>

                  <p className="subtitle">אני מאמין</p>
                  <p>
                    דור הקשישים הנוכחי בישראל הינו דור עתיר זכויות, אשר שרד
                    רדיפות וניסיונות השמדה, עלה לארץ ישראל, יישב אותה, בנה את
                    עריה, נלחם במלחמותיה והיה נכון להקריב את היקר לו מכל, למען
                    עתידנו אנו. על כן, אני רואה זאת כזכות, ולכל הפחות, חובה
                    מוסרית, לעשות כל שביכולתנו בכדי לגמול לאזרחי המדינה הקשישים
                    בערוב ימיהם, ולאפשר להם להזדקן בכבוד הראוי להם. כחלק מאני
                    מאמין זה, אני מקפיד כי עובדי החברה ינהגו בחמלה, סובלנות
                    ואנושיות כלפי מטופלי החברה מתוך ההכרה וההבנה שיש לראות כל
                    מטופל, לפני הכל, כאדם הנמצא בתקופה הרגישה והקשה בחייו. כמו
                    כן, אני דורש מצוות החברה ובראש ובראשונה מעצמי, לעמוד באמות
                    מידה גבוהות, הן מהבחינה המקצועיות והן מבחינת רמת השירות
                    הניתן ללקוחות החברה. לבסוף, לצד התווית בסיס ערכים מוצק לאורו
                    פועלת החברה, אני מעודד ביקורת עצמית ולמידה מתמדת, מתוך ראייה
                    שרק כך, נוכל להמשיך לעמוד בחוד החנית, ולספק ללקוחותינו את
                    השירות הטוב ביותר.
                  </p>
                  <div className="portfolio-view-list d-flex flex-wrap">
                    <div className="port-view">
                      <span>דואר אלקטרוני</span>
                      <h4>yogev@arazim.co</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <FooterTwo />
    </React.Fragment>
  );
};
export default Management;
